.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-header {
  background: linear-gradient(to right, rgb(125, 182, 214), rgb(125, 182, 214));
  height: 60px; /* Increase header height */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  position: sticky;
  top: 0;
  width: 100%; 
  z-index: 100;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #333;
}

.header-logo {
  height: 50px; /* Adjust logo height as needed */
  position: absolute; /* Position the logo absolutely */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust for logo's width */
}

.app-header a {
  color: #333;
  text-decoration: none;
  margin-left: 15px;
}

.app-header a:hover {
  text-decoration: underline;
}

.header-links {
  display: flex;
  padding: 20px;
}

.last-updated {
  margin-right: auto;
  padding: 20px;
}

.app-content {
  display: flex;
  flex-grow: 1;
}

.app-sidebar {
  position: fixed;
  width: 265px;
  background-color: #f0f0f0;
  padding: 20px;
  overflow-y: auto;
  flex-shrink: 0;
}

.app-main {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  margin-left: 300px; /* added margin to account for the fixed side bar*/
  background: linear-gradient(to right, rgb(227, 223, 203), rgb(227, 223, 203));
}


.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.image-item {
  text-align: center;
}

.image-item img:not(.full-size-image) { /* Apply height only to non-full-size images */
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.comment-section {
  margin-top: 20px;
}


.clickable-image {
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  position: relative; /* Needed for close button positioning */
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: red; /* Change color to red */
  font-weight: bold; /* Make it bold */
}

.full-size-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.full-size-image {
  width: 600px;
  height: auto;
  object-fit: contain;
}

.app-sidebar {
  /* ... (other sidebar styles) */
  position: fixed;
  top: 40px; /* Adjust top to account for the header */
  left: 0;
  height: calc(100vh - 40px); /* Adjust height to account for the header */
  overflow-y: auto;
}

.main-logo-container {
  width: 100%;
  height: 100px; /* Set a fixed height for the container */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px; /* Add some top margin for spacing */
}

.main-logo-image {
  max-width: 600px; /* Limit the image width */
  height: auto; /* Allow height to adjust proportionally */
  object-fit: contain;
}